import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var router = [{
  path: '/user',
  redirect: '/user/index'
}, {
  path: '/user/index',
  name: 'User',
  component: function component() {
    return import('@/views/UserCenter/User/index');
  },
  meta: {
    keepAlive: true,
    title: '个人中心'
  }
}, {
  path: '/login',
  name: 'Login',
  component: function component() {
    return import('@/views/UserCenter/Login');
  },
  meta: {
    title: '登录页'
  }
}, {
  path: '/user/paySuccess',
  name: 'paySuccessInfo',
  component: function component() {
    return import('@/components/PaySuccess/PaySuccess');
  },
  meta: {
    title: '购买成功'
  }
}, {
  path: '/paysuccess',
  name: 'PaySuccess',
  component: function component() {
    return import('@/views/PaySuccess/PaySuccess');
  },
  meta: {
    title: '充值成功'
  }
}, {
  path: '/user/share-friends',
  name: 'ShareFriends',
  component: function component() {
    return import('@/views/UserCenter/ShareFriends');
  },
  meta: {
    title: '邀请好友得积分'
  }
}, {
  path: '/user/share-poster',
  name: 'SharePoster',
  component: function component() {
    return import('@/views/UserCenter/SharePoster');
  },
  meta: {
    title: '专属二维码'
  }
}, {
  path: '/user/share-poster-mini',
  name: 'SharePosterMini',
  component: function component() {
    return import('@/views/UserCenter/SharePoster');
  },
  meta: {
    title: '专属二维码'
  }
}, {
  path: '/user/recharge-record',
  name: 'RechargeRecord',
  component: function component() {
    return import('@/views/UserCenter/RechargeRecord/index');
  },
  meta: {
    keepAlive: true,
    title: '充值记录'
  }
}, {
  path: '/user/point-record',
  name: 'PointRecord',
  component: function component() {
    return import('@/views/UserCenter/PointRecord/index');
  },
  meta: {
    title: '积分明细'
  }
}, {
  path: '/user/share-record',
  name: 'ShareRecord',
  component: function component() {
    return import('@/views/UserCenter/ShareRecord/index');
  },
  meta: {
    title: '我的邀请记录'
  }
}, {
  path: '/user/coupon',
  name: 'UserCoupon',
  component: function component() {
    return import('@/views/UserCenter/Coupon');
  },
  meta: {
    title: '优惠券'
  }
}, {
  path: '/user/coupon/coupon-record',
  name: 'CouponRecord',
  component: function component() {
    return import('@/views/UserCenter/Coupon/CouponRecord');
  },
  meta: {
    title: '优惠券记录'
  }
}, {
  path: '/user/timeMachine',
  name: 'TimeMachine',
  component: function component() {
    return import('@/views/UserCenter/TimeMachine');
  },
  meta: {
    title: '游全球时光机'
  }
}, {
  path: '/user/orderDetail',
  name: 'OrderDetail',
  component: function component() {
    return import('@/views/UserCenter/OrderDetail');
  },
  meta: {
    title: '订单详情'
  }
}, {
  path: '/user/complaint',
  name: 'Complaint',
  component: function component() {
    return import('@/views/UserCenter/Complaint');
  },
  meta: {
    title: '订单投诉'
  }
}, {
  path: '/ylpaysuccess',
  name: 'YLPaySuccess',
  component: function component() {
    return import('@/components/YLPaySuccess/index.vue');
  },
  meta: {
    title: '充值成功'
  }
}, {
  path: '/shareQrCode',
  name: 'ShareQrCode',
  component: function component() {
    return import('@/components/ShareQrCode/index.vue');
  },
  meta: {
    title: '分享二维码'
  }
}, {
  path: '/user/groupChat',
  name: 'groupChat',
  component: function component() {
    return import('@/views/UserCenter/groupChat/index.vue');
  },
  meta: {
    title: '加入粉丝群'
  }
}];
export default router;