import "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/core-js/modules/es.promise.js";
import "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/core-js/modules/es.object.assign.js";
import "/Users/jackh/Desktop/2024-web/20240305/global-charge-front/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// import store from './store'

// import axios from 'axios'
// Vue.prototype.$axios = axios

import 'reset-css';
import '@/style/index.scss';
import '@/permission';
import '@/assets/iconfont/iconfont.css';

// import '@/utils/iconfont'
//字体图标
import '@/utils/iconfontNormal';
// import '@/utils/iconfontCountry'

// 自动引入icons下的所有svg，需要打开时开启
// const req = require.context('@/icons', false, /\.svg$/)
// const requireAll = requireContext => requireContext.keys().map(requireContext)
// requireAll(req)

// 数据上报
import MtaH5 from 'mta-h5-analysis';
MtaH5.init({
  "sid": '500720693',
  // 必填，统计用的appid
  "cid": '500720828',
  // 如果开启自定义事件，此项目为必填，否则不填
  "autoReport": 0,
  // 是否开启自动上报(1:init完成则上报一次,0:使用pgv方法才上报)
  "performanceMonitor": 1 // 是否开启性能监控
});
MtaH5.pgv();

// lazyLoad 指令
// import { Lazyload } from 'vant'
// Vue.use(Lazyload, {})

// 动态rem 在index.html添加 适配手机端
import 'amfe-flexible';

//引入bugsnag工具（用于检测网页错误）
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'
// Bugsnag.start({
//   apiKey: '15f4302ec6edb5be80a75c6ff0206b66',
//   plugins: [new BugsnagPluginVue()],
// })
// const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)

// 金额保留两位小数
import { toFixed2, toFixed0 } from '@/utils/index';
Vue.filter('toFixed2', toFixed2);
Vue.filter('toFixed0', toFixed0);

// import VueMeta from 'vue-meta'
// Vue.use(VueMeta)
// Vue.use(VueMeta, {
//   keyName: 'metaInfo',
//   attribute: 'data-vue-meta',
//   ssrAttribute: 'data-vue-meta-server-rendered',
//   tagIDKeyName: 'vmid',
//   refreshOnceOnNavigation: true
// })
// console.log("输入路由")
// console.log(router)

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-subscribe", "wx-open-launch-webapp"];
import '@vant/touch-emulator'; //vant PC端适配

import VConsole from 'vconsole';
var isPc = function isPc() {
  var userAgentInfo = navigator.userAgent;
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
};
//如果不是生产环境并且不是pc设备那么就显示调试
if (process.env.NODE_ENV != "production" && !isPc()) {
  console.log(process.env.NODE_ENV);
  var vConsole = new VConsole();
}
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  },
  beforeCreate: function beforeCreate() {
    Vue.prototype.$bus = this;
  },
  mounted: function mounted() {
    // 当 Vue 应用挂载完成后，移除加载动画
    var loadingElement = document.getElementById('loading');
    if (loadingElement) {
      loadingElement.style.display = 'none'; // 隐藏加载动画
    }
    document.dispatchEvent(new Event('custom-render-trigger'));
  }
}).$mount('#app');