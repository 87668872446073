import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import getters from './getters';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    user: user
  },
  getters: getters,
  state: {
    loginVisible: false,
    //登录对话框
    tempPayPopupVisible: false,
    //微信支付临时弹框
    taxValueArr: JSON.parse(localStorage.getItem('taxValueArr')) || [],
    //国家税率
    isFirstRechargeUser: false,
    isSucceedRechargeUser: false,
    nowSelectCountryCode: "",
    isLoginUser: false,
    orderVisible: false,
    //订单详情显示弹窗
    recommendCount: null //推荐人数
  },
  mutations: {
    changeLoginVisible: function changeLoginVisible(state, payload) {
      // console.log('222',payload);
      state.loginVisible = payload;
    },
    changeTempPayPopupVisible: function changeTempPayPopupVisible(state, payload) {
      state.tempPayPopupVisible = payload;
    },
    changeTaxValueArr: function changeTaxValueArr(state, payload) {
      state.taxValueArr = payload;
    },
    // 定义一个 用户是否首充用户
    setIsFirstRechargeUser: function setIsFirstRechargeUser(state, payload) {
      state.isFirstRechargeUser = payload;
    },
    // 订单详情显示弹窗
    setOrderVisible: function setOrderVisible(state, payload) {
      state.orderVisible = payload;
    },
    setRecommendCount: function setRecommendCount(state, payload) {
      state.recommendCount = payload;
    },
    //用户是否已经有成功充值记录(不再请求判断是否为首单接口)
    setIsSucceedRechargeUser: function setIsSucceedRechargeUser(state, payload) {
      state.isSucceedRechargeUser = payload;
    },
    setNowSelectCountryCode: function setNowSelectCountryCode(state, payload) {
      state.nowSelectCountryCode = payload;
    },
    // 定义用户是否登录
    setIsLoginUser: function setIsLoginUser(state, payload) {
      state.isLoginUser = payload;
    }
  }
});
export default store;